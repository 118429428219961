<template>
  <div class="results-view container">
    <div class="logo"><router-link to="/"><img src="@/assets/images/storesafe-logo.png" width="100%" /></router-link></div>
    <div v-if="isLoading">Loading...</div>
    <AssessmentResults 
      v-if="assessment && assessment.answers && questions.length"
      :answers="assessment.answers" 
      :questions="questions"
      :currentQuestionIndex="assessment.currentQuestionIndex"
      :assessmentTitle="assessment.title"
      @continue-assessment="continueAssessment"
    />
    <div v-else>Failed to load assessment data.</div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import AssessmentResults from '@/components/AssessmentResults.vue';
import { auth } from '@/api';

export default {
  components: {
    AssessmentResults
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    
    const assessment = ref(null);
    const questions = ref([]);
    const isLoading = ref(true);

    const continueAssessment = () => {
      console.log('Continuing assessment');
      router.push({ 
        name: 'Assessment', 
        params: { id: assessment.value.id } 
      });
    };

    const fetchData = async () => {
      try {
        const [assessmentResponse, questionsResponse] = await Promise.all([
          axios.get(`/api/assessment/${route.params.id}`, {
            withCredentials: true,
            baseURL: 'https://mystoresafe.com'
          }),
          axios.get('/api/questions', {
            withCredentials: true,
            baseURL: 'https://mystoresafe.com'
          })
        ]);

        assessment.value = assessmentResponse.data;
        questions.value = questionsResponse.data;
        
        console.log('Data loaded:', {
          assessment: assessment.value,
          questions: questions.value,
          answersCount: Object.keys(assessment.value.answers || {}).length,
          totalQuestions: questions.value.length
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      try {
        const authResponse = await auth.checkAuth();
        if (!authResponse.isAuthenticated) {
          router.push('/login');
          return;
        }
        await fetchData();
      } catch (error) {
        console.error('Auth check failed:', error);
        router.push('/login');
      }
    });

    return {
      assessment,
      questions,
      isLoading,
      continueAssessment
    };
  }
};
</script>