<template>
  <div id="app" :class="currentRouteName">
    <div class="menu-container" :class="{ 'menu-open': isMenuOpen }">
      <button class="hamburger mobile" @click="toggleMenu">
        <span :class="{ 'active': isMenuOpen }"></span>
      </button>

      <nav>
        <router-link v-if="!isLoggedIn" to="/register" @click.native="closeMenu">Create an Account</router-link>
        <router-link v-if="!isLoggedIn" to="/login" @click.native="closeMenu">Log In</router-link>
        <span v-if="isLoggedIn">Welcome, {{ user?.name }}!</span>
        <router-link v-if="isLoggedIn" to="/start-assessment" @click.native="closeMenu">New Assessment</router-link>
        <router-link v-if="isLoggedIn" to="/dashboard" @click.native="closeMenu">Dashboard</router-link>
        <a v-if="isLoggedIn" href="#" @click.prevent="logout">Logout</a>
        <router-link v-if="isAdmin" to="/admin" @click.native="closeMenu">Admin Dashboard</router-link>
      </nav>
    </div>
    <router-view @login-success="handleLoginSuccess"/>
  </div>
</template>

<script>
import { auth } from '@/api';

export default {
  name: 'App',
  data() {
    return {
      isMenuOpen: false,
      user: null
    };
  },
  computed: {
    isLoggedIn() {
      return !!this.user;
    },
    isAdmin() {
      return this.user?.isAdmin || false;
    },
    currentRouteName() {
      return this.$route.name || '';
    }
  },
  methods: {
    async checkAuth() {
      try {
        const { isAuthenticated, user } = await auth.checkAuth();
        if (isAuthenticated) {
          this.user = user;
        } else {
          this.user = null;
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        this.user = null;
      }
    },
    handleLoginSuccess(user) {
      this.user = user;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    async logout() {
      try {
        await auth.logout();
        this.user = null;
        this.isMenuOpen = false;
        this.$router.push('/login');
      } catch (error) {
        console.error('Logout failed:', error);
      }
    }
  },
  created() {
    this.checkAuth();
  }
};
</script>