<template>
  <div class="login container mt-5">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/images/storesafe-logo.png" width="100%" alt="StoreSafe logo" />
      </router-link>
    </div>
    <h1 class="text-center">Login</h1>
    <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
    
    <form @submit.prevent="login" class="mt-3">
      <div class="form-group">
        <label for="email">Email:</label>
        <input 
          type="email" 
          id="email"
          v-model="email" 
          class="form-control" 
          required 
        />
      </div>
      
      <div class="form-group">
        <label for="password">Password:</label>
        <input 
          type="password" 
          id="password"
          v-model="password" 
          class="form-control" 
          required 
        />
      </div>
      
      <button 
        type="submit" 
        class="btn btn-primary btn-block mt-3 btn-login"
        :disabled="isLoading"
      >
        {{ isLoading ? 'Logging in...' : 'Login' }}
      </button>
    </form>

    <div class="text-center mt-3">
      <p><router-link to="/forgot-password">Forgot Password?</router-link></p>
      <p>Need to create an account? 
        <router-link to="/register">Create Account</router-link>
        <br />
        Register for free access to food safety tools and resources.
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { auth } from '@/api';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'Login',
  emits: ['login-success'],
  
  setup(props, { emit }) {
    const store = useStore(); 
    const router = useRouter(); 
    const email = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const isLoading = ref(false);

    const login = async () => {
     errorMessage.value = '';
     isLoading.value = true;
     console.log('Starting login process');
    
     try {
       const response = await auth.login(email.value, password.value);
       console.log('Login response received:', response);
       
       if (response?.user) {
         // Update Vuex store with user data
         store.commit('setUser', response.user);
         emit('login-success', response.user);
         
         console.log('Login successful, user state:', store.state.auth.user);
         
         // Navigate to dashboard
         await router.push('/dashboard');
       } else {
         console.error('Invalid response structure:', response);
         errorMessage.value = 'Invalid server response';
       }
     } catch (error) {
       console.error('Login error:', {
         message: error.message,
         response: error.response?.data,
         status: error.response?.status
       });
       
       // Handle different error cases
       if (error.response?.status === 500) {
         errorMessage.value = 'Server error - please try again later';
       } else if (error.response?.data?.error) {
         errorMessage.value = error.response.data.error;
       } else {
         errorMessage.value = 'Login failed - please try again';
       }
     } finally {
       isLoading.value = false;
     }
    };

    return {
      email,
      password,
      errorMessage,
      isLoading,
      login
    };
  }
};
</script>