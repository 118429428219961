<template>
  <div class="assessment container">
    <div class="logo">
      <img src="@/assets/images/storesafe-logo.png" alt="StoreSafe logo" />
    </div>

    <h1 class="assessment-title">{{ assessmentTitle }}</h1>
    
    <div class="category-navigation">
      <div v-for="category in categories" :key="category" class="category-section">
        <button 
          :class="{ active: currentCategory === category }"
          @click="jumpToCategory(category)"
        >
          {{ category }}
        </button>
        <div class="question-dots">
          <span 
            v-for="(question, index) in questionsByCategory[category]" 
            :key="index"
            class="dot"
            :class="[getDotClass(question.id), 
                    { 'current-question': currentCategory === category && index === currentQuestionIndex }]"
          ></span>
        </div>
      </div>
    </div>

    <!-- <div class="question-dots">
      <span 
        v-for="(question, index) in questionsByCategory[currentCategory]" 
        :key="index"
        class="dot"
        :class="getDotClass(question.id)"
      ></span>
    </div> -->

    <div v-if="loading">Loading questions...</div>
    <div v-else-if="error">Error: {{ error }}</div>
    <div v-if="currentQuestion" class="question-container">
      <button @click="navigateQuestion(-1)" class="nav-button left"><img src="@/assets/images/left-arrow.svg" alt="Previous Question" class="nav-arrow"/></button>
      
      <div class="question-content">
        <h2>{{ currentQuestionIndex + 1 }}. {{ currentQuestion.question }}</h2>
        <div class="yes-no">
          <button 
            @click="answerQuestion(currentQuestion.id, true)" 
            :class="['yes', { active: answers[currentQuestion.id] === true }]"
          >
            Yes
          </button>
          <button 
            @click="answerQuestion(currentQuestion.id, false)" 
            :class="['no', { active: answers[currentQuestion.id] === false }]"
          >
            No
          </button>
        </div>
      </div>
      
      <button @click="navigateQuestion(1)" class="nav-button right"><img src="@/assets/images/right-arrow.svg" alt="Next Question" class="nav-arrow"/></button>
    </div>

    <button @click="saveAndReturn" class="save-progress">
      Save & Return to Dashboard
    </button>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import AssessmentResults from '@/components/AssessmentResults.vue';
import { auth } from '@/api';

export default {
  name: 'Assessment',
  components: { AssessmentResults },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // State
    const assessmentId = ref(route.params.id);
    const assessmentTitle = ref('');
    const questions = ref([]);
    const categories = ref(['receiving', 'storage', 'preparation', 'service', 'sanitation']);
    const currentCategory = ref('');
    const currentQuestionIndex = ref(0);
    const questionsByCategory = ref({});
    const lastAnsweredQuestionIndex = ref({});
    const answers = ref({});
    const loading = ref(true);
    const error = ref(null);
    const isRep = ref(false);
    
    const organizeQuestionsByCategory = () => {
      if (!Array.isArray(questions.value)) {
        console.error('Questions is not an array:', questions.value);
        return;
      }
    
      questionsByCategory.value = categories.value.reduce((acc, category) => {
        acc[category] = questions.value
          .filter(q => q.category === category)
          .map(q => ({...q, category}));
        return acc;
      }, {});
    };
    
    const initializeLastAnsweredIndices = () => {
      categories.value.forEach(category => {
        if (!lastAnsweredQuestionIndex.value[category]) {
          const categoryQuestions = questionsByCategory.value[category] || [];
          const lastAnsweredIndex = categoryQuestions.findIndex(q => answers.value[q.id] === undefined);
          lastAnsweredQuestionIndex.value[category] = lastAnsweredIndex === -1 ? categoryQuestions.length : lastAnsweredIndex;
        }
      });
    };
    
    const setInitialCategory = () => {
      currentCategory.value = categories.value[0];
      currentQuestionIndex.value = lastAnsweredQuestionIndex.value[currentCategory.value] || 0;
    };
    
    const jumpToCategory = (category) => {
      console.log('Jumping to category:', category);
      currentCategory.value = category;
      const questions = questionsByCategory.value[category] || [];
      const firstUnansweredIndex = questions.findIndex(q => answers.value[q.id] === undefined);
      currentQuestionIndex.value = firstUnansweredIndex !== -1 ? firstUnansweredIndex : questions.length - 1;
      window.scrollTo(0, 0);
    };
    
    const getDotClass = (questionId) => {
      if (answers.value[questionId] === undefined) {
        return 'unanswered';
      }
      return answers.value[questionId] ? 'answered-yes' : 'answered-no';
    };
    
    // Add these computed properties
    const currentQuestion = computed(() => {
      return questionsByCategory.value[currentCategory.value]?.[currentQuestionIndex.value];
    });
    
    const progress = computed(() => {
      const categoryQuestions = questionsByCategory.value[currentCategory.value] || [];
      return categoryQuestions.length ? 
        ((currentQuestionIndex.value + 1) / categoryQuestions.length) * 100 : 0;
    });
    
    const isAssessmentComplete = computed(() => {
      return Object.keys(answers.value).length === questions.value.length;
    });
    
    const navigateQuestion = (direction) => {
      const allQuestions = categories.value.flatMap(category => questionsByCategory.value[category]);
      const currentOverallIndex = allQuestions.findIndex(q => 
        q.category === currentCategory.value && 
        q.id === questionsByCategory.value[currentCategory.value][currentQuestionIndex.value].id
      );
    
      let newIndex = (currentOverallIndex + direction + allQuestions.length) % allQuestions.length;
    
      // Find the new category and question index
      const newQuestion = allQuestions[newIndex];
      currentCategory.value = newQuestion.category;
      currentQuestionIndex.value = questionsByCategory.value[currentCategory.value].findIndex(q => q.id === newQuestion.id);
    
      // Save the current position
      saveAssessment();
    };
    
    const answerQuestion = async (questionId, answer) => {
      answers.value[questionId] = answer;
      
      try {
        const currentCategoryQuestions = questionsByCategory.value[currentCategory.value];
        const nextQuestionIndex = currentQuestionIndex.value + 1;
    
        if (nextQuestionIndex < currentCategoryQuestions.length) {
          // Move to the next question in the current category
          currentQuestionIndex.value = nextQuestionIndex;
        } else {
          // We've reached the end of the current category
          const nextCategoryIndex = categories.value.indexOf(currentCategory.value) + 1;
          
          if (nextCategoryIndex < categories.value.length) {
            // Move to the next category
            currentCategory.value = categories.value[nextCategoryIndex];
            currentQuestionIndex.value = 0;
          } else {
            // We've reached the end of all categories
            if (isAllQuestionsAnswered()) {
              // All questions are answered, go to results
              await saveAssessment();
              router.push(`/results/${assessmentId.value}`);
              return;
            } else {
              // Find the first unanswered question from the beginning
              const firstUnansweredQuestion = findNextUnansweredQuestion();
              if (firstUnansweredQuestion) {
                currentCategory.value = firstUnansweredQuestion.category;
                currentQuestionIndex.value = questionsByCategory.value[currentCategory.value]
                  .findIndex(q => q.id === firstUnansweredQuestion.id);
              }
            }
          }
        }
        
        await saveAssessment();
      } catch (error) {
        console.error('Error saving answer:', error);
      }
    };

    const fetchAssessment = async () => {
      loading.value = true;
      error.value = null;
      try {
        const authResponse = await auth.checkAuth();
        if (!authResponse.isAuthenticated) {
          router.push('/login');
          return;
        }
    
        console.log('Fetching assessment:', {
          id: assessmentId.value,
          isRep: authResponse.user.isRep
        });
    
        const response = await axios.get(`/api/assessment/${assessmentId.value}`, {
          withCredentials: true,
          baseURL: 'https://mystoresafe.com'
        });
        
        console.log('Assessment response:', response.data);
        const assessment = response.data;
        assessmentTitle.value = assessment.title;
        answers.value = assessment.answers || {};
        currentQuestionIndex.value = assessment.currentQuestionIndex || 0;
        currentCategory.value = assessment.currentCategory || categories.value[0];
        lastAnsweredQuestionIndex.value = assessment.lastAnsweredQuestionIndex || {};
        
        await fetchQuestions();
        initializeLastAnsweredIndices();
      } catch (err) {
        console.error('Error fetching assessment:', err);
        error.value = 'Failed to load assessment';
        if (err.response?.status === 401) {
          router.push('/login');
        }
      } finally {
        loading.value = false;
      }
    };

    const fetchQuestions = async () => {
      try {
        const response = await axios.get('/api/assessment/questions', {
          withCredentials: true,
          baseURL: 'https://mystoresafe.com'
        });
        questions.value = response.data;
        organizeQuestionsByCategory();
        setInitialCategory();
      } catch (error) {
        console.error('Error fetching questions:', error);
        error.value = 'Failed to load questions';
      }
    };

    const saveAssessment = async () => {
      try {
        const payload = {
          title: assessmentTitle.value,
          answers: answers.value,
          currentCategory: currentCategory.value,
          currentQuestionIndex: currentQuestionIndex.value,
          userId: store.getters.getUser?.id,
          completed: isAllQuestionsAnswered(),
          lastAnsweredQuestionIndex: lastAnsweredQuestionIndex.value
        };
    
        const response = await axios.put(`/api/assessment/${assessmentId.value}`, payload, {
          withCredentials: true,
          baseURL: 'https://mystoresafe.com'
        });
        
        console.log('Assessment saved successfully:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error saving assessment:', error);
        throw error;
      }
    };
    
    const saveAndReturn = async () => {
      try {
        console.log('Starting save and return process');
        await saveAssessment();
        console.log('Assessment saved, navigating to dashboard');
        // Try both ways to ensure navigation works
        await router.push('/dashboard').catch(err => {
          console.error('Navigation failed, trying alternative:', err);
          window.location.href = '/dashboard';
        });
      } catch (error) {
        console.error('Error in save and return:', error);
        // Still try to navigate even if save fails
        router.push('/dashboard').catch(err => {
          window.location.href = '/dashboard';
        });
      }
    };
    
    const isAllQuestionsAnswered = () => {
      return questions.value.every(question => answers.value[question.id] !== undefined);
    };
    
    const findNextUnansweredQuestion = () => {
      for (const category of categories.value) {
        const unansweredQuestion = questionsByCategory.value[category]
          .find(q => answers.value[q.id] === undefined);
        if (unansweredQuestion) {
          return { ...unansweredQuestion, category };
        }
      }
      return null;
    };

    onMounted(async () => {
      try {
        const authResponse = await auth.checkAuth();
        if (!authResponse.isAuthenticated) {
          console.log('Not authenticated, redirecting to login');
          router.push('/login');
          return;
        }
        console.log('Auth successful, fetching assessment');
        await fetchAssessment();
      } catch (error) {
        console.error('Error in mounted:', error);
        router.push('/login');
      }
    });

    return {
      assessmentTitle,
      questions,
      categories,
      currentCategory,
      currentQuestionIndex,
      questionsByCategory,
      answers,
      loading,
      error,
      currentQuestion,
      isRep,
      progress,
      isAssessmentComplete,
      fetchAssessment,
      fetchQuestions,
      organizeQuestionsByCategory,
      saveAndReturn,
      jumpToCategory,
      setInitialCategory,
      initializeLastAnsweredIndices,
      getDotClass,
      navigateQuestion,
      answerQuestion,
      saveAssessment,
      isAllQuestionsAnswered,
      findNextUnansweredQuestion
    };
  }
};
</script>