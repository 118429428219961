import { createRouter, createWebHistory } from 'vue-router';
import { auth } from '@/api';
import Home from '../views/Home.vue';
import Register from '../views/Register.vue';
import Login from '../views/Login.vue';
import StartAssessment from '../views/StartAssessment.vue';
import Assessment from '../views/Assessment.vue';
import RepAssessment from '../views/RepAssessment.vue';  // Add direct import
import Dashboard from '../views/Dashboard.vue';
import ResultsView from '../views/ResultsView.vue';
import AdminDashboard from '../views/AdminDashboard.vue';

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/register', name: 'Register', component: Register },
  { path: '/login', name: 'Login', component: Login },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'UpdatePassword',
    component: () => import('@/views/UpdatePassword.vue'),
    meta: { requiresAuth: false },
    props: true
  },
  {
    path: '/reset-link',
    name: 'ResetLink',
    component: () => import('@/views/ResetLink.vue')
  },
  { 
    path: '/dashboard', 
    name: 'Dashboard', 
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  { 
    path: '/start-assessment', 
    name: 'StartAssessment', 
    component: StartAssessment,
    meta: { requiresAuth: true }
  },
{
    path: '/assessment/:id',
    name: 'Assessment',
    component: Assessment, // Use single Assessment component
    meta: { requiresAuth: true },
    props: true
  },
  { 
    path: '/results/:id', 
    name: 'Results', 
    component: ResultsView, 
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  console.log('Route guard - checking auth for:', to.path);
  
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (!requiresAuth) {
    return next();
  }

  try {
    const authResponse = await auth.checkAuth();
    console.log('Auth check response:', authResponse);

    if (authResponse.isAuthenticated) {
      if (to.path === '/login') {
        // If already authenticated and trying to access login page,
        // redirect to dashboard
        next('/dashboard');
      } else {
        next();
      }
    } else {
      console.log('Not authenticated, redirecting to login');
      next('/login');
    }
  } catch (error) {
    console.error('Auth check failed:', error);
    next('/login');
  }
});

export default router;