<template>
  <div class="register container mt-5">
    <div class="logo"><router-link to="/"><img src="@/assets/images/storesafe-logo.png" width="100%" /></router-link></div>
    <h1 style="text-align: center;">Create Account</h1>
    <form @submit.prevent="register">
      <div class="form-group">
        <label for="name">Name: <span class="red">*</span></label>
        <input type="text" v-model="name" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="company">Company: <span class="red">*</span></label>
        <input type="text" v-model="company" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="industry">Industry: <span class="red">*</span></label>
        <select v-model="industry" class="form-control" required>
          <option value="" disabled>Select One</option>
          <option value="Independent Restaurant">Independent Restaurant</option>
          <option value="Restaurant Group">Restaurant Group</option>
          <option value="Hotel">Hotel</option>
          <option value="Catering">Catering</option>
          <option value="Chain Restaurant">Chain Restaurant</option>
          <option value="Supermarket">Supermarket</option>
          <option value="Convenience Store">Convenience Store</option>
          <option value="K-12 School">K-12 School</option>
          <option value="College & University">College & University</option>
          <option value="Corrections">Corrections</option>
          <option value="Military/Government">Military/Government</option>
          <option value="Business & Industry">Business & Industry</option>
        </select>
      </div>
      <div class="form-group">
        <label for="email">Email: <span class="red">*</span></label>
        <input type="email" v-model="email" class="form-control" required />
      </div>
      <!-- <div class="form-group">
        <label for="phone">Phone:</label>
        <input type="text" v-model="phone" class="form-control" required />
      </div> -->
      <div class="form-group">
        <label for="password">Password: <span class="red">*</span></label>
        <small class="form-text text-muted">At least 8 characters with 1 uppercase, lowercase, number and special character (ex. !@#$%)</small>
        <div class="password-input">
          <input :type="showPassword ? 'text' : 'password'" v-model="password" class="form-control" required />
          <i class="fas fa-eye" @click="showPassword = !showPassword" :class="{ 'fa-eye-slash': showPassword }"></i>
          <i v-if="password && confirmPassword && passwordsMatch" class="fas fa-check-circle text-success"></i>
        </div>
      </div>
      <div class="form-group">
        <label for="confirmPassword">Confirm Password: <span class="red">*</span></label>
        <div class="password-input">
          <input :type="showConfirmPassword ? 'text' : 'password'" v-model="confirmPassword" class="form-control" required />
          <i class="fas fa-eye" @click="showConfirmPassword = !showConfirmPassword" :class="{ 'fa-eye-slash': showConfirmPassword }"></i>
          <i v-if="password && confirmPassword && passwordsMatch" class="fas fa-check-circle text-success"></i>
        </div>
      </div>
      
      <div class="form-group">
        <label>Are you a Cambro rep? <span class="red">*</span></label>
        <div>
          <input type="radio" id="repNo" v-model="isCambroRep" :value="false" class="rep-radio" checked>
          <label for="repNo" class="rep-label">No</label>
          <input type="radio" id="repYes" v-model="isCambroRep" :value="true" class="rep-radio">
          <label for="repYes" class="rep-label">Yes</label>
        </div>
      </div>
      <div class="cleaner"></div>
      
      <p v-if="passwordError" class="text-danger">{{ passwordError }}</p>
      <button type="submit" class="btn-register" :disabled="!passwordsMatch">Register</button>
      <p v-if="error" class="text-danger">{{ error }}</p>
    </form>
    <div style="text-align: center;width:100%;">
      Have an account already? &nbsp; 
      <router-link v-if="!isLoggedIn" to="/login">Log In</router-link>
    </div>
  </div>
  <!-- <div class="login" style="text-align: center;">
    
  </div> -->
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import authClient from '@/authClient'

export default {
  data() {
    return {
      name: '',
      company: '',
      industry: '',
      email: '',
      password: '',
      confirmPassword: '',
      isCambroRep: false,
      error: '',
      passwordError: '',
      showPassword: false,
      showConfirmPassword: false
    };
  },
  computed: {
    passwordsMatch() {
      return this.password === this.confirmPassword;
    }
  },
  watch: {
    password() {
      this.validatePasswords();
    },
    confirmPassword() {
      this.validatePasswords();
    }
  },
methods: {
   ...mapActions(['loginUser']),
   validatePassword(password) {
     const minLength = 8;
     const hasUpperCase = /[A-Z]/.test(password);
     const hasLowerCase = /[a-z]/.test(password);
     const hasNumbers = /\d/.test(password);
     const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
     return {
       isValid: password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar,
       errors: [
         password.length < minLength && 'Password must be at least 8 characters',
         !hasUpperCase && 'Password must contain at least one uppercase letter',
         !hasLowerCase && 'Password must contain at least one lowercase letter',
         !hasNumbers && 'Password must contain at least one number',
         !hasSpecialChar && 'Password must contain at least one special character'
       ].filter(Boolean)
     };
   },
   validatePasswords() {
     const validation = this.validatePassword(this.password);
     if (!validation.isValid) {
       this.passwordError = validation.errors[0];
       return;
     }
     if (this.password !== this.confirmPassword) {
       this.passwordError = "Passwords do not match.";
     } else {
       this.passwordError = "";
     }
   },
   async register() {
     const validation = this.validatePassword(this.password);
     if (!validation.isValid) {
       this.error = validation.errors[0];
       return;
     }
     if (!this.passwordsMatch) {
       this.error = "Passwords do not match. Please try again.";
       return;
     }
     try {
       const response = await axios.post(`/api/users/register`, {
         name: this.name,
         company: this.company,
         industry: this.industry,
         email: this.email,
         password: this.password,
         isRep: this.isCambroRep,
       });
       console.log('Registration successful:', response.data);
       
       // Set user data using authClient
       authClient.setUserData(response.data);
       
       // Commit the user data to the Vuex store
       this.$store.commit('setUser', response.data.user);
       
       // Redirect to dashboard
       if (this.isCambroRep) {
         this.$router.push('/assessment');
       } else {
         this.$router.push('/dashboard');
       }
     } catch (error) {
       if (error.response) {
         console.error('Error response:', error.response);
         this.error = error.response.data.error || error.response.statusText;
       } else if (error.request) {
         console.error('Error request:', error.request);
         this.error = 'No response received from server.';
       } else {
         console.error('Error message:', error.message);
         this.error = error.message;
       }
     }
   }
  },
};
</script>