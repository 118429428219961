// src/api.js
import axios from 'axios';

let routerInstance = null;

export const setRouter = (router) => {
  console.log('Setting router instance');
  routerInstance = router;
};

// Create axios instance with cross-domain support
const api = axios.create({
  baseURL: 'https://mystoresafe.com',
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  crossDomain: true
});

// Add request interceptor for logging
api.interceptors.request.use(
  config => {
    console.log(`API Request: ${config.method.toUpperCase()} ${config.url}`);
    return config;
  },
  error => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
api.interceptors.response.use(
  response => {
    console.log(`API Response: ${response.config.method.toUpperCase()} ${response.config.url}`, 
      { status: response.status, data: response.data });
    return response;
  },
  error => {
    console.error('API Response Error:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });

    if (error.response?.status === 401 && routerInstance) {
      console.log('Unauthorized access, redirecting to login');
      routerInstance.push('/login');
    }
    return Promise.reject(error);
  }
);

// Auth methods
export const auth = {
  login: async (email, password) => {
    console.log('Attempting login for:', email);
    try {
      const response = await api.post('/api/auth/login', { email, password });
      console.log('Login successful:', {
        isAuthenticated: true,
        userId: response.data.user?.id,
        timestamp: new Date().toISOString()
      });
      return response.data;
    } catch (error) {
      console.error('Login failed:', {
        status: error.response?.status,
        message: error.response?.data?.error || error.message,
        timestamp: new Date().toISOString()
      });
      throw error;
    }
  },

  logout: async () => {
    console.log('Attempting logout');
    try {
      const response = await api.post('/api/auth/logout'); // Updated path
      console.log('Logout successful');
      return response.data;
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  },

  checkAuth: async () => {
    console.log('Checking authentication status');
    try {
      const response = await api.get('/api/auth/check-auth'); // Updated path
      console.log('Auth check result:', {
        isAuthenticated: response.data.isAuthenticated,
        userId: response.data.user?.id,
        timestamp: new Date().toISOString()
      });
      return response.data;
    } catch (error) {
      console.error('Auth check failed:', {
        error: error.message,
        timestamp: new Date().toISOString()
      });
      return {
        isAuthenticated: false,
        user: null,
        error: error.message,
        timestamp: new Date().toISOString()
      };
    }
  }
};

// Test connection on import should also use the updated path
api.get('/api/auth/check-auth')
  .then(response => console.log('Initial auth check:', response.data))
  .catch(error => console.log('Initial auth check failed:', error.message));

// Test connection on import
api.get('/auth/check-auth')
  .then(response => console.log('Initial auth check:', response.data))
  .catch(error => console.log('Initial auth check failed:', error.message));

export default api;